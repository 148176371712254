import React from 'react';

const RightGalleryVideo = ({ mainImage, miniImages, title, subtitle, description, infoBox, id, miniMedia }) => {
  return (
    <div className="container mx-auto my-8 px-4 lg:px-8" id={`${id}`}>
    <div className="flex flex-col lg:flex-row items-end justify-center">
        <div className="relative lg:w-1/3 w-full p-4 lg:order-3 max-lg:order-2">
        <img
            src={mainImage}
            alt="Main"
            className="w-full h-auto object-cover rounded-lg shadow-lg"
        />
        </div>        
        <div className="lg:w-1/2 w-full lg:p-4 max-lg:text-center lg:order-2 lg:text-right max-lg:order-1">
        <p className='font-bold lg:text-[1.2em] md:text-[2em] max-sm:text-[1.5em]'>{title}</p>
        <h2 className="lg:text-[2em] md:text-[1.5em] font-bold text-[#C30D3F]">{subtitle}</h2>
        <p className="text-md mb-6 lg:pl-20 pt-2 md:text-[1.2em]">{description}</p>
        <div className="flex space-x-4 relative bottom-0">
        {miniMedia.map((mediaItem, index) => (
          <div
            key={index}
            className={`w-1/2 lg:w-1/2 rounded border-4 border-white shadow-lg items-center ${
              index === 1 ? 'lg:-mr-20 z-10' : 'z-10'
            }`}
          >
            {mediaItem.type === 'video' ? (
              <video autoPlay loop muted className="">
                <source src={mediaItem.src} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={mediaItem.src}
                alt={`Mini ${index + 1}`}
                className=""
              />
            )}
          </div>
        ))}
        </div>
        </div>
        <hr className="border-t-2 border-black my-4 w-full lg:hidden" />
  </div>
    </div>

  );
};

export default RightGalleryVideo;
