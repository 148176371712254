import React from 'react';
import { Link } from 'react-router-dom';
const IconCard = ({ Icon, title, description }) => (
  <div className="flex sm:flex-col items-center text-left sm:text-center mb-6 sm:mb-0">
    <Icon className="text-5xl max-sm:text-8xl mb-2 sm:mb-0 mr-4 sm:mr-0" style={{color: "#C30D3F"}} />
    <div>
      <h2 className=" text-lg font-bold">{title}</h2>
      <p className="lg:text-md text-sm">{description}</p>
    </div>
  </div>
);

const IconSection = ({ cards, buttonText, buttonLink, button }) => {
  return (
    <div className="container mx-auto">
      <div className="block justify-center">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:space-x-4 mt-6">
          {cards.map((card, index) => (
            <IconCard 
              key={index}
              Icon={card.icon} 
              title={card.title} 
              description={card.description} 
            />
          ))}
        </div>
        <div className={`flex lg:justify-${button} justify-center mt-6`}>
          <Link to={buttonLink} className="bg-red-500 text-white font-bold py-2 px-4 rounded">
            {buttonText}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default IconSection;
