import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png'; // Adjust the path according to your project structure
import { FaLinkedin, FaEnvelope, FaTiktok, FaInstagram } from 'react-icons/fa';
import '../stylesheets/nav_foo.css';

function CustomNavbar() {
  return (
    <Navbar variant="dark" expand="lg" className="custom-navbar sticky-top">
      <Navbar.Brand as={Link} to="/">
        <img
          src={logo}
          className="navbar-logo"
          alt="MySite logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mx-auto mid">
          <Nav.Link as={Link} to="/">Home</Nav.Link>
          <Nav.Link as={Link} to="/team">About</Nav.Link>
          <Nav.Link as={Link} to="/solutions">Solutions</Nav.Link>
          <Nav.Link as={Link} to="/#projects">Projects</Nav.Link>
        </Nav>
        <Nav className="social-links">
          <Nav.Link href="https://sg.linkedin.com/company/ghealthtech" target="_blank" rel="noopener noreferrer">
            <FaLinkedin size={22}/>
          </Nav.Link>
          <Nav.Link href="https://www.tiktok.com/@ght_sg?_t=8nq35zXsVP6&_r=1">
            <FaTiktok size={22}/>
          </Nav.Link>
          <Nav.Link href="https://www.instagram.com/greathealthtechnologies?igsh=Z2U1cjg1dWpvOXlx">
            <FaInstagram size={22}/>
          </Nav.Link>
          <Nav.Link href="mailto:admin@ght.sg">
            <FaEnvelope size={22}/>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default CustomNavbar;
