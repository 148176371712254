import React from 'react';

const ImageTextSection = ({ imageSrc, title, subtitle, description, aclassName }) => {
  return (
    <div className={`flex flex-col lg:flex-row justify-between items-center max-sm:mt-[-8rem] mt-[-10rem] px-[50px] split ${aclassName}`}>
      <div className="flex-1 p-[20px] max-sm:text-center md:text-center lg:text-right lg:mt-[8%] hex-title">
        <p className='font-bold lg:text-[1.2em]'>{title}</p>
        <h2 className="lg:text-[1.8em] lg:w-[45%] lg:ml-[55%] font-bold text-[#C30D3F] hex-text">{subtitle}</h2>
        <p className="lg:text-[1.1em] lg:w-[65%] lg:ml-[35%] leading-[1.5] mt-0 hex-text">{description}</p>
      </div>

      <div className="flex-1 flex justify-center items-center z-10">
        <svg className="about__blob lg:w-[500px] w-[300px]" viewBox="0 0 550 592" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g>
            <path
              transform="rotate(-45 274.5 315)"
              d="M285 51.6423L499.157 175.286C505.345 178.859 509.157 185.461 509.157 
              192.606V439.894C509.157 447.039 505.345 453.641 499.157 457.214L285 
              580.858C278.812 584.43 271.188 584.43 265 580.858L50.843 457.214C44.655 453.641 
              40.843 447.039 40.843 439.894V192.606C40.843 185.461 44.655 178.859 50.843 
              175.286L265 51.6423C271.188 48.0697 278.812 48.0696 285 51.6423Z"
              stroke="black"
              strokeWidth="10"
            />
          </g>
        </svg>
        <img src={imageSrc} className="absolute lg:w-[30%] lg:block z-1 lg:mr-[12%] mt-[7%] hex" alt="Overlay" />
      </div>
    </div>
  );
};

export default ImageTextSection;