import React from 'react';

const TwoColumnSection = ({ mainImage, miniImages, title, subtitle, description, infoBox, id }) => {
  return (
    <div className="container mx-auto my-8 px-4 lg:px-8" id={`${id}`}>
      <div className="flex flex-col lg:flex-row items-end justify-center">
        <div className="relative lg:w-1/3 w-full p-4 max-lg:order-2">
          <img
            src={mainImage}
            alt="Main"
            className="w-full h-auto object-cover rounded-lg shadow-lg"
          />
        </div>
        
        <div className="lg:w-1/2 w-full lg:p-4 max-lg:text-center max-lg:order-1">
          <p className='font-bold lg:text-[1.2em] md:text-[2em] max-sm:text-[1.5em]'>{title}</p>
          <h2 className="lg:text-[2em] md:text-[1.5em] font-bold text-[#C30D3F]">{subtitle}</h2>
          <p className="text-md mb-6 lg:pr-20 pt-2 md:text-[1.2em]">{description}</p>
          <div className="flex space-x-4 relative bottom-0">
            {miniImages.map((miniImage, index) => (
              <img
                key={index}
                src={miniImage}
                alt={`Mini ${index + 1}`}
                className={`w-1/2 lg:w-1/2 rounded border-4 border-white shadow-lg items-center ${
                  index === 0 ? 'lg:-ml-20 z-10' : 'z-10'
                }`}
              />
            ))}
          </div>
        </div>
        <hr className="border-t-2 border-black my-4 w-full lg:hidden" />
      </div>
    </div>
  );
};

export default TwoColumnSection;
