// Home.js
import 'bootstrap/dist/css/bootstrap.min.css';
import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import '../stylesheets/home.css';
import videoSource from '../assets/ght_intro.MP4'; 
import slantedImage from '../assets/bee1.png'; 
import aboutImage from '../assets/about1.png'; 
import aboutTeam from '../assets/about_team.png';
import IconSection from '../components/values';
import { FaPeopleArrows, FaLaptopCode, FaRobot, FaHandsHelping, FaHandHoldingWater, FaChartLine } from 'react-icons/fa';
import HoneyComb from '../components/honeycomb';
import news1 from '../assets/news1.jpg';
import news2 from '../assets/news2.jpg';
import news3 from '../assets/news3.jpg';
import news4 from '../assets/news4.jpg';
import news5 from '../assets/news5.jpg';
import news6 from '../assets/news6.jpg';
import Slideshow from '../components/slideshow';
import beemail from '../assets/beemail.png';
import Footer from '../components/footer';
import Counter from '../components/counter';
import projvid from '../assets/projvid.mp4';
import ghtplain from '../assets/ght_plain.png';
import ghtwhite from '../assets/ghtwhite.png';

const Home = () =>{

  const cards = [
    {
      icon: FaPeopleArrows,
      title: 'Process',
      description: 'Reduce unplanned work, improve patient safety and operation processes. Enhance workflow and minimise overhead costs.'
    },
    {
      icon: FaLaptopCode,
      title: 'Hardware',
      description: 'Streamline repetitive human tasks, freeing up time for pharmacists to focus on higher value tasks.'
    },
    {
      icon: FaRobot,
      title: 'Software',
      description: 'Daily operation data is collated to enhance products and customer experience via dashboards and AI platforms.'
    }
  ];

  const cards2 = [
    {
      icon: FaHandsHelping,
      title: 'Shared Success',
      description: 'We believe in making a difference with the purposeful inclusion of the community in our success story.'
    },
    {
      icon: FaChartLine,
      title: 'Desire for Growth',
      description: 'We see each challenge as a stepping stone to success, an opportunity to learn, grow and improve.'
    },
    {
      icon: FaHandHoldingWater,
      title: 'Love for Environment',
      description: 'We champion sustainability and wellbeing, cultivating a thriving future for all, to preserve our planet and empowering people.'
    }
  ];

  const news = [
    { image: news1, name: 'Together, a smarter healthcare system (CNA)', role: '18 February, 2022', link: "https://www.channelnewsasia.com/watch/tech4health/part-one-2506026"},
    { image: news2, name: 'Empowered for better patient care (IHiS)', role: '12 April, 2019', link: "https://www.ihis.com.sg/Latest_News/voices_of_our_people/Pages/Empowered-for-better-patient-care.aspx"},
    { image: news3, name: 'Punggol Polyclinic', role: '23 May, 2018', link: "https://www.straitstimes.com/singapore/robot-tech-at-new-punggol-polyclinic-to-cut-wait-time-for-patients-at-pharmacy"},
    { image: news4, name: 'Tan Tock Seng Hospital', role: '15 September, 2014', link: "https://www.moh.gov.sg/news-highlights/details/speech-by-minister-for-health-mr-gan-kim-yong-at-the-official-opening-of-tan-tock-seng-hospital-b2-pharmacy-featuring-soft-launch-of-outpatient-pharmacy-automated-system-15-september-2014"},
    { image: news5, name: 'Singapore General Hospital', role: '14 August, 2014', link: "https://www.sgh.com.sg/news/patient-care/sgh-pharmacy-uses-rfid-technology-and-automated-system-to-enhance-medication-safety-and-improve-operational-efficiency"},
    { image: news6, name: 'KK Women’s and Children’s Hospital', role: '4 September, 2015', link: "https://www.sgh.com.sg/news/patient-care/kkh-rolls-out-new-robotic-system-at-emergency-pharmacy"},
  ];

  const [secondLineVisible, setSecondLineVisible] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setSecondLineVisible(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  // const initialValues = {
  //   automationMachines: 57,
  //   patientsServed: 4201,
  //   drugsDispensed: 403929
  // };

  // const maxValues = {
  //   automationMachines: 100,
  //   patientsServed: 5000,
  //   drugsDispensed: 500000
  // };

  // const startDate = new Date(2024, 5, 25); 
  // const getMonthsDifference = (start, end) => {
  //   return (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());
  // };

  // const calculateNewValues = (monthsPassed) => {
  //   let newValues = {
  //     automationMachines: initialValues.automationMachines + monthsPassed,
  //     patientsServed: initialValues.patientsServed + monthsPassed * 5,
  //     drugsDispensed: initialValues.drugsDispensed + monthsPassed * 50
  //   };

  //   newValues.automationMachines = newValues.automationMachines > maxValues.automationMachines
  //     ? initialValues.automationMachines
  //     : newValues.automationMachines;

  //   newValues.patientsServed = newValues.patientsServed > maxValues.patientsServed
  //     ? initialValues.patientsServed
  //     : newValues.patientsServed;

  //   newValues.drugsDispensed = newValues.drugsDispensed > maxValues.drugsDispensed
  //     ? initialValues.drugsDispensed
  //     : newValues.drugsDispensed;

  //   return newValues;
  // };

  // const [values, setValues] = useState(initialValues);

  // useEffect(() => {
  //   const now = new Date();
  //   const monthsPassed = getMonthsDifference(startDate, now);

  //   const newValues = calculateNewValues(monthsPassed);
  //   setValues(newValues);
  // }, []);


  return (
    <div>
    <div className="relative h-[50vh] lg:h-[90vh] md:h-[50vh] z-10 video-container">
      <video className="w-full h-full object-cover brightness-[70%] fullscreen-video" autoPlay loop muted>
        <source src={videoSource} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <img src={slantedImage} className="absolute bottom-[-20%] right-0 w-[20%] lg:w-[30%] hidden md:block overlay-bee" alt="Slanted Overlay" />
      <div className="absolute top-[15%] left-[10%] right-[10%] w-[70%] text-white text-left overlay-text">
        <div className="flex items-center mb-2 lg:text-[5.5em] fade-in">
        <h1 className={`${secondLineVisible ? 'remove-border' : ''}`}>
          Li<strong>ght</strong>
        </h1>
          <img src={ghtwhite} alt="Your Image" className=" mx-2 w-[5%] fade-in custom-move-up" />
          <h1 className={`${secondLineVisible ? 'remove-border' : ''}`}>
            up the future of
          </h1>
        </div>
        <h1 className={`mb-2 lg:text-[5.5em] fade-in`}>
          Pharmacy Automation
        </h1>
        <p className="text-[0.7em] lg:text-[2em] md:text-[1.5em] fade-in">
          Sharing what we have learnt from our end-to-end pharmacy journey.
        </p>
      </div>
    </div>
    <div className="flex flex-col lg:flex-row justify-between items-center mt-[-6rem] px-[50px] max-sm:px-[10px] split">
    <div className="flex-1 flex justify-center items-center z-10">
      <svg className="about__blob lg:w-[500px] w-[300px]" viewBox="0 0 550 592" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            transform="rotate(45 274.5 315)"
            d="M285 51.6423L499.157 175.286C505.345 178.859 509.157 185.461 509.157 
            192.606V439.894C509.157 447.039 505.345 453.641 499.157 457.214L285 
            580.858C278.812 584.43 271.188 584.43 265 580.858L50.843 457.214C44.655 453.641 
            40.843 447.039 40.843 439.894V192.606C40.843 185.461 44.655 178.859 50.843 
            175.286L265 51.6423C271.188 48.0697 278.812 48.0696 285 51.6423Z"
            stroke="black"
            strokeWidth="10"
          />
        </g>
      </svg>
      <img src={aboutImage} className="absolute lg:w-[25%] lg:block z-1 lg:ml-[12%] mt-[7%] hex" alt="Overlay" />
    </div>
    <div className="flex-1 p-[20px] max-sm:text-center md:text-center lg:text-left lg:mt-[8%] hex-title">
      <p className='font-bold lg:text-[1.2em] '>Home</p>
      <h2 className="lg:text-[2.5em] font-bold text-[#C30D3F]">Enabling Through Automation</h2>
      <p className="lg:text-[1.2em] leading-[1.5] mt-0">With doing good being our virtue and pharmacy solutions our expertise, we provide a range of solutions ranging from hardware to software.</p>
      <IconSection 
        cards={cards} 
        buttonText="Learn more" 
        buttonLink="/solutions" 
        button="start"
      />
    </div>
    </div>
    <div className="flex flex-col lg:flex-row justify-between px-[10px] split home_bg2">
    <div className="flex-1 p-[20px] md:p-[50px] max-sm:text-center md:text-center lg:text-right hex-title">
  <p className='font-bold lg:text-[1.2em]'>About Us</p>
  <h2 className="lg:text-[2.5em] font-bold text-[#C30D3F] flex items-center justify-center lg:justify-end ri-ght">
    Doing it the Ri-ght
    <img src={ghtplain} alt="Your Image" className="mx-2 w-[5%] inline-block custom-move-up" />
    Way
  </h2>
  <div className="lg:block lg:bg-gray-600 lg:px-6 lg:py-4 lg:-ml-14 lg:-mr-14 lg:text-white">
  <p className="lg:text-[1.1em] leading-[1.5] lg:mt-2">
  Why settle for normal when you can be gr8? At ght, we believe you're always capable of achieving more than you think. We foster a vibrant community that celebrates diversity – the gr8 kind that sparks innovation and keeps our young spirits ignited. This platform is all about self-discovery, empowering you to unleash your greatness and contribute to a happy, thriving community.
  </p>
  <p className="lg:text-[1.1em] leading-[1.5] lg:mt-2">
    -Joseph Tan, Founder of ght
  </p>
  </div>
  <p className="lg:text-[1.5em] lg:pt-4 max-lg:pt-5 text-center font-bold">Values We Drive:</p>
  <IconSection 
    cards={cards2} 
    buttonText="Learn more" 
    buttonLink="/team" 
    button="center"
  />
</div>
    <div className="flex-1 flex justify-center items-center lg:mt-[-10%] z-10 overflow-hidden ">
    <img src={aboutTeam} className="lg:w-[100%] mt-[7%] relative ateam" alt="Overlay" />
      <svg className="absolute about__blob2 lg:w-[550px] w-[250px] md:w-[530px]" viewBox="0 0 550 592" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            transform="rotate(45 274.5 315)"
            d="M285 51.6423L499.157 175.286C505.345 178.859 509.157 185.461 509.157 
            192.606V439.894C509.157 447.039 505.345 453.641 499.157 457.214L285 
            580.858C278.812 584.43 271.188 584.43 265 580.858L50.843 457.214C44.655 453.641 
            40.843 447.039 40.843 439.894V192.606C40.843 185.461 44.655 178.859 50.843 
            175.286L265 51.6423C271.188 48.0697 278.812 48.0696 285 51.6423Z"
            stroke="black"
            strokeWidth="10"
          />
        </g>
      </svg>
    </div>

    </div>

    <div className="flex justify-center w-full text-white font-bold py-12 max-sm:py-2 px-4 w-full button-bg">
      <Link to='/team'>
      <button className="">
        <p className='text-xl max-md:text-sm py-2'>Interested to find out more?</p>
        <h1 className='text-5xl max-md:text-xl font-bold underline'>Hear from Our Team!</h1>
      </button>
      </Link>
    </div>
    <div className="flex flex-col lg:flex-row h-full">
    <div class="lg:w-2/3">
      <video src={projvid} autoPlay muted className="lg:w-[100%]" />
    </div>
    <div class="w-full lg:w-1/3 items-center justify-center max-lg:text-center mt-6" id='projects'>
      <div>
        <h1 class="text-2xl font-bold text-[#C30D3F] px-4">Your Everyday Heroes</h1>
        <p class="text-lg px-4">We make every effort count to a greater tomorrow.</p>
        <div className="flex flex-col items-center w-full bg-gray-200 max-sm:mt-4 mt-10">
          <div className='text-center px-4 pt-4'>
            <h1 class="text-4xl font-bold text-[#C30D3F]"><Counter end={57} /></h1>
            <p class="text-lg font-bold">Automation Machines used in Singapore</p>
            <hr className="border-t-2 border-black my-4 w-full" />
          </div>
          <div className='text-center px-4'>
            <h1 class="text-4xl font-bold text-[#C30D3F]"><Counter end={6201} /></h1>
            <p class="text-lg font-bold">Patients Served Everyday</p>
            <hr className="border-t-2 border-black my-4 w-full" />
          </div>
          <div className='text-center px-4 pb-4'>
            <h1 class="text-4xl font-bold text-[#C30D3F]"><Counter end={403929} /></h1>
            <p class="text-lg font-bold">Drugs Dispensed Everyday</p>
          </div>
        </div>
      </div>
    </div>
  </div>
    <HoneyComb />
    <div className="news lg:pb-96 pb-40 lg:mt-[-50px] md:mt-0 bg-cover bg-no-repeat bg-center bg-gray-100 relative max-sm:mt-0 max-sm:pt-0 md:pt-4">
      <div className="title flex flex-row items-left justify-left lg:ml-40 md:ml-10 text-left text-white py-2 px-4 lg:px-0 mr-[10%]">
        <div>
          <p className="font-bold lg:text-[1.2em] text-black">News</p>
          <h1 className="lg:text-[2.5em] font-bold text-[#C30D3F]">News Release</h1>
          <p className="mt-2 text-gray-500">Click to read</p>
        </div>
      </div>
      <div className="h-[200px] max-lg:h-[100px] absolute left-0 right-0 bg-[#636569] z-0" />
      <img
        src={beemail}
        alt="Bee 2"
        className="absolute right-0 w-1/4 lg:w-1/5 lg:mt-[-190px] md:mt-[-120px] max-sm:mt-[-80px] lg:mr-60 mr-20"
      />
      <Slideshow
        employees={news}
        additional="border border-gray-900 p-2 bg-white rounded mt-20 max-lg:mt-10"
        textalign="text-left"
      />
    </div>
    <Footer/>
    </div>
  );
}

export default Home;
