import React, { useEffect, useRef, useState } from 'react';
import CountUp from 'react-countup';

const Counter = ({ end }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [key, setKey] = useState(0); // Key to force re-render
  const countRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(countRef.current);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5, // Trigger when 50% of the element is visible
      }
    );

    if (countRef.current) {
      observer.observe(countRef.current);
    }

    return () => {
      if (countRef.current) {
        observer.unobserve(countRef.current);
      }
    };
  }, [key]); // Key dependency ensures useEffect runs on each key change

  const resetAnimation = () => {
    setIsVisible(false);
    setKey(prevKey => prevKey + 1); // Increment key to force re-render
  };

  return (
    <span ref={countRef} onClick={resetAnimation}> {/* Reset on click (optional) */}
      <CountUp key={key} end={isVisible ? end : 0} duration={2.5} />
    </span>
  );
};

export default Counter;
