import React from 'react';
import '../stylesheets/nav_foo.css'; 
import { FaEnvelope, FaLinkedin, FaTiktok, FaInstagram } from 'react-icons/fa'; 
import logo from '../assets/logo.png';

const Footer = ({ noBackground }) => {
  return (
    <div className={`footer-wrapper z-0 relative h-[320px] lg:h-[500px] overflow-hidden mt-[-150px] max-sm:mt-[-130px] lg:mt-[-380px] ${noBackground ? 'footer-no-bg' : ''}`}>
      <footer className="footer absolute bottom-0 w-full h-[300px] bg-[#4D4D4D] text-white flex flex-col items-center justify-center clip-path-footer">
        <div className="footer-content container mx-auto py-6 flex justify-center items-center">
          <div className="left-column flex flex-col items-center text-center px-2 lg:ml-[-150px]">
            <img src={logo} alt="Logo" className="w-40 mb-4" />
            <div className="social-icons flex space-x-4 mb-4">
              <a href="https://sg.linkedin.com/company/ghealthtech" target="_blank" rel="noopener noreferrer">
                <FaLinkedin className="text-2xl" />
              </a>
              <a href="https://www.tiktok.com/@ght_sg?_t=8nq35zXsVP6&_r=1" target="_blank" rel="noopener noreferrer">
                <FaTiktok className="text-2xl" />
              </a>
              <a href="https://www.instagram.com/greathealthtechnologies?igsh=Z2U1cjg1dWpvOXlx" target="_blank" rel="noopener noreferrer">
                <FaInstagram className="text-2xl" />
              </a>
              <a href="mailto:admin@ght.sg" target="_blank" rel="noopener noreferrer">
                <FaEnvelope className="text-2xl" />
              </a>
            </div>
          </div>

          <div className="right-column flex flex-col items-left text-left max-md:px-4 px-20">
            <h2 className='font-bold lg:text-xl'>Corporate Headquarters:</h2>
            <p className='lg:text-lg'>159 Sin Ming Road #03-08</p>
            <p className='lg:text-lg'>Amtech Building</p>
            <p className='lg:text-lg'>Singapore 575625</p>
          </div>
        </div>
        
        <div className="copyright bg-gray-700 text-center py-2 absolute bottom-0 w-full flex items-center justify-center">
          <p className="text-sm">&copy; 2024 Great Health Technologies Pte Ltd.</p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
