import React, {useState, useEffect} from 'react';
import ImageTextSection from '../components/hex_content';
import solutions1 from '../assets/solutions1.png';
import solbg from '../assets/sol_bg.png';
import Footer from '../components/footer';
import proj1 from '../assets/proj1.png';
import proj2 from '../assets/proj2.png';
import proj3 from '../assets/proj3.png';
import TwoColumnSection from '../components/proj_gallery';
import proj31 from '../assets/proj3-1.png';
import proj32 from '../assets/proj3-2.png';
import proj33 from '../assets/proj3-3.png';
import proj21 from '../assets/proj2-1.png';
import proj22 from '../assets/proj2-2.png';
import proj23 from '../assets/proj2-3.png';
import '../stylesheets/team.css';
import TwoColumnSectionRight from '../components/proj_gallery_right';
import ProjectGalleryVid from '../components/proj_gallery_vid';
import RightGalleryVideo from '../components/proj_gallery_rightvid';
import asrs from '../assets/asrs.png';
import asrs1 from '../assets/asrs1.jpg';
import asrs2 from '../assets/asrs2.jpg';
import dpas from '../assets/DPAS.jpeg';
import dpas1 from '../assets/DPAS1.jpeg';
import dpas2 from '../assets/DPAS2.jpeg';
import bdsvid from '../assets/bds.mp4';
import ddsvid from '../assets/dds.mp4';
import starvid from '../assets/star.mp4';


const Solutions = () =>{
    const [secondLineVisible, setSecondLineVisible] = useState(false);
    useEffect(() => {
      const timer = setTimeout(() => {
        setSecondLineVisible(true);
      }, 2000);
  
      return () => clearTimeout(timer);
    }, []);
  
    return (
        <div>
        <div className="relative h-[50vh] lg:h-[90vh] md:h-[50vh] z-10 video-container">
            <img src={solbg} className='w-full h-full object-cover fullscreen-video' alt='team' />
            <div className="absolute top-[45%] left-[10%] right-[10%] w-[70%] text-white text-left overlay-text">
                <h1 className={`mb-2 font-bold lg:text-[5em] fade-in`}>
                    Empowering all
                </h1>
                <h1 className={`mb-2 font-bold lg:text-[5em] typing-animation fade-in`}>Pharmacists</h1>
                <p className="text-[0.7em] lg:text-[2em] md:text-[1.5em]">Our Expertise</p>
            </div>  
        </div>
        <ImageTextSection
            imageSrc={solutions1}
            aclassName={'img-bg1'}
            title='Our Expertise'
            subtitle='Enabling Through Digitisation'
            description='With years of pharmacy engineering experience supported by data insight, we are here to transform how pharmacies manage their workflow, preparing for future workloads and digitilisation.'
        />
        <div className='proj_bg pb-96 max-lg:pb-40'>
        <div className='projects text-center mt-10'>
            <h2 className='font-bold text-[#C30D3F] lg:text-[2em]'>Project Gallery</h2>
        </div>
        <ProjectGalleryVid
            mainImage={proj1}
            miniMedia={[{src: proj2, type: 'image'}, {src: ddsvid, type: 'video'}]}
            title=""
            subtitle="Drug Dispensing System (DDS)"
            description="The Drug Dispensing System (DDS) is a versatile and robust blister and box package dispenser with autopick, autopack and autolabel capabilities. It has a modular build for easy transport."
            infoBox={{
                title1: "20",
                content1: "Machines Dispatched",
                title2: "10",
                content2: "Sites Present"
              }}
            id="DDS"
        />
        <RightGalleryVideo
            mainImage={proj21}
            miniMedia={[{src: starvid, type: 'video'}, {src: proj23, type: 'image'}]}
            title=""
            subtitle="System, Tote Assembly and Retrieval (STAR)"
            description="The STAR delivers a combination  of patient’s orders from the backend pharmacy to  buffer storage pigeon- holes in the pharmacy counter for hassle-free dispensing."
            infoBox={{
                title1: "5",
                content1: "Machines Dispatched",
                title2: "3",
                content2: "Sites Present"
              }}
            id="STAR"
        />
        <ProjectGalleryVid
            mainImage={proj31}
            miniMedia={[{src: proj32, type: 'image'}, {src: bdsvid, type: 'video'}]}
            title=""
            subtitle="Bottle Dispensing System (BDS)"
            description="The Bottle Dispensing System (BDS) is a versatile and robust drug bottle dispenser with autoload, autopick and autolabel capabilities. It has a modular build for easy transport."
            infoBox={{
                title1: "3",
                content1: "Machines Dispatched",
                title2: "2",
                content2: "Sites Present"
              }}
            id="BDS"
        />
        <TwoColumnSectionRight
            mainImage={asrs}
            miniImages={[asrs1, asrs2]}
            title=""
            subtitle="Advanced Drug Storage and Retrieval (ADSR)"
            description="The Advanced Drug Storage and Retreival (ADSR) serves as a semi-automated storage and dispensing system which enhances efficiency, productivity, and accuracy in storage operations. They maximize space utilization by leveraging vertical storage, reduce labor costs through automation, and improve inventory management with real-time tracking."
            infoBox={{
                title1: "2",
                content1: "Machines Dispatched",
                title2: "1",
                content2: "Sites Present"
              }}
            id="ADRS"
        />
        <TwoColumnSection
            mainImage={dpas1}
            miniImages={[dpas, dpas2]}
            title=""
            subtitle={"Drug Packages Assembly System (DPAS)"}
            description={"DPAS automates the assembly and consolidation of drug packages from various automations, significantly boosting efficiency by minimising human error and manual intervention. Additionally, guided lights on pigeonhole assists Pharmacists in providing faster, safer and smoother operations."}
            infoBox={{
                title1: "2",
                content1: "Machines Dispatched",
                title2: "1",
                content2: "Sites Present"
              }}
            id="DPAS"
        />

        </div>
        <Footer/>
        </div>
    )

}

export default Solutions;