import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import Logo1 from "../assets/logo_1.png";
import Logo2 from "../assets/logo_2.png";
import Logo3 from "../assets/logo_3.png";
import LogoMain from "../assets/logo_main.png";
import mascot from "../assets/mascot.png";
import mini1 from "../assets/mini_logo1.png";
import mini2 from "../assets/mini_logo2.png";
import mini3 from "../assets/mini_logo3.png";

export default function Logo() {
  const slides = [
    {
      id: 1,
      image: LogoMain,
      title: "Our Logo",
      description: "ght designs and builds technology solutions for pharmacies worldwide. Our mission is to transform pharmacy capabilities to enhance patient experience. <br> <br> Since inception, our key priorities have been to understand our users needs and continuously innovate to bring new solutions. <br> <br> As such, ght logo represents what we do and what we believe in.",
      tags: ["Pharmacy", "People", "Culture"],
      tagged_image: [mini1, mini2, mini3]
    },
    {
      id: 2,
      image: Logo1,
      title: "Our Logo",
      description: "<h1>1. Pharmacy</h1> The logo highlights our mission to transform pharmacy capabilities worldwide with the aid of technology. <br><br> As such, the logo is shaped like a pill with a technology heart, representing our expertise in pharmacy operations, a quality promise to our healthcare partners.",
      tags: ["Pharmacy", "People", "Culture"],
      tagged_image: [mini1, mini2, mini3]
    },
    {
      id: 3,
      image: Logo2,
      title: "Our Logo",
      description: "<h1>2. People</h1> The 3 motifs in the center resembles 3 people bowing to each other, representing the respect we drive at for customers and team players. <br><br> The act of bowing also represents our keen attitude at open-minded learning from both the seniors and the juniors.",
      tags: ["Pharmacy", "People", "Culture"],
      tagged_image: [mini1, mini2, mini3]
    },
    {
      id: 4,
      image: Logo3,
      title: "Our Logo",
      description: "<h1> 3. Culture </h1> The dynamic twist which breaks away from the convention of the hexagonal shape has two meanings: <br><br> 1. Resembling a finger-heart, this represents the empathy we drive towards, not restricted to people but also to the work we do. <br> 2. Ability to think out of the box and be dynamic.",
      tags: ["Pharmacy", "People", "Culture"],
      tagged_image: [mini1, mini2, mini3]
    },
    {
      id: 5,
      image: mascot,
      title: "Say Hi to Bean!",
      description: "<h1>Introducing Bean</h1> Bean is a glowing bee that is productive and hardworking. He hopes that his non-expiring “Honey” of his labour will improve healthcare globally! Always willing to learn, grow and care for the environment, he hopes to be an inspiring light to all!",
      tags: "<h1>The Tagline</h1>'Light Up the Future' unites our team's aim to be part of the driving force of tomorrow's future.<br><br> 'In Light there is ght, and in the future there is us!'",
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(slides[0]);
  const [startIndex, setStartIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 100000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, [currentSlide]);

  const changeSlide = (index) => {
    setCurrentSlide(slides[index]);
  };

  const nextSlide = () => {
    setStartIndex((prevIndex) => (prevIndex === slides.length - 3 ? 0 : prevIndex + 1));
    const currentIndex = slides.findIndex((slide) => slide.id === currentSlide.id);
    const nextIndex = (currentIndex + 1) % slides.length;
    setCurrentSlide(slides[nextIndex]);
  };

  const prevSlide = () => {
    setStartIndex((prevIndex) => (prevIndex === 0 ? slides.length - 3 : prevIndex - 1));
    const currentIndex = slides.findIndex((slide) => slide.id === currentSlide.id);
    const prevIndex = currentIndex === 0 ? slides.length - 1 : currentIndex - 1;
    setCurrentSlide(slides[prevIndex]);
  };

  return (
    <div className="relative">
      <div className="logo h-[500px] absolute lg:top-[30%] top-[50%] left-0 right-0 bg-red-600 z-0" />
      <div className="relative max-w-[1000px] lg:h-[550px] md:h-[900px] max-sm:h-[750px] justify-center mx-auto rounded-md p-4 lg:flex z-10 relative m-4">
        <div className="bg-gray-200 p-2 w-full rounded-t-md lg:rounded-l-md">
          <h3 className="text-red-800 text-2xl font-bold text-right mt-4 mr-10">
            {currentSlide.title}
          </h3>
          <p className="text-right mr-10 text-gray-600">Interact with me!</p>
          <div className="relative flex items-center gap-2 justify-center">
            <div className="relative flex items-center justify-center h-[400px] w-[400px]  md:h-[300px] md:w-[300px] max-sm:h-[250px] max-sm:w-[250px]">
              <img
                src={currentSlide.image}
                alt=""
                className={`h-full object-contain ${currentSlide.id === 5 ? 'lg:mt-10 lg:px-10' : ''}`}
              />
              <IoIosArrowForward
                fontSize={30}
                onClick={nextSlide}
                className="absolute right-0 top-1/2 transform -translate-y-1/2 cursor-pointer"
                style={{ fill:"gray" }}
              />
              <IoIosArrowBack
                fontSize={30}
                onClick={prevSlide}
                className="absolute left-0 top-1/2 transform -translate-y-1/2 cursor-pointer"
                style={{ color: 'gray' }}
              />
            </div>
          </div>
          <div className="flex justify-center mt-4 space-x-2">
          {slides.map((slide, index) => (
            <div
              key={slide.id}
              className={`w-3 h-3 rounded cursor-pointer ${currentSlide.id === slide.id ? 'bg-red-600' : 'bg-gray-300'}`}
              onClick={() => changeSlide(index)}
            />
          ))}
        </div>
        </div>
        <div className="bg-[#636569] py-2 w-full rounded-b-md lg:rounded-r-md p-4">
          <div className="my-4 border-t border-b p-4 text-white flex flex-col">
            <p className="min-h-[100px] max-sm:text-xs" dangerouslySetInnerHTML={{ __html: currentSlide.description.replace(/\n/g, '<br>') }} />
            <button className="border rounded-full py-2 px-4 self-end text-sm mt-2">
              <Link to="/team">
                Learn More
              </Link>
            </button>
          </div>
          {currentSlide.tags && currentSlide.tagged_image && (
            <div className="flex flex-col items-center max-sm:hidden">
              <ul className="flex flex-wrap gap-20 text-white text-sm">
                {currentSlide.tags.map((e, i) => (
                  <li key={i}>{e}</li>
                ))}
              </ul>
              <div className="flex gap-2 items-center">
                {currentSlide.tagged_image.map((imagePath, i) => (
                  <div key={i} className="flex items-center justify-center">
                    <img
                      src={imagePath}
                      alt={`Tagged Image ${i + 1}`}
                      className="w-[80px] lg:w-[100px] rounded-md m-2"
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          {currentSlide.tags && !currentSlide.tagged_image && (
            <p className="text-white my-4 px-4 max-sm:hidden" dangerouslySetInnerHTML={{ __html: currentSlide.tags.replace(/\n/g, '<br>') }} />
          )}
        </div>
      </div>
    </div>
  );
}
