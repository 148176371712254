import React, { useState, useEffect, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';
import '../stylesheets/team.css';
import { Link } from 'react-router-dom';

const Slideshow = ({ employees, additional, textalign }) => {
  const [hoveredEmployee, setHoveredEmployee] = useState(employees[0]);
  const [startIndex, setStartIndex] = useState(0);
  const [displayCount, setDisplayCount] = useState(4);
  const slidesRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 821) {
        setDisplayCount(3);
      } if (window.innerWidth < 768) {
        setDisplayCount(2);
      } else {
        setDisplayCount(4);
      }
    };

    handleResize(); // Set initial value
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Auto-scroll functionality
  useEffect(() => {
    const autoScrollInterval = setInterval(() => {
      setStartIndex((prevStartIndex) => (prevStartIndex + displayCount) % employees.length);
    }, 8000);

    return () => clearInterval(autoScrollInterval);
  }, [displayCount, employees.length]);

  const handlePageClick = (pageIndex) => {
    setStartIndex(pageIndex * displayCount);
  };

  const totalPages = Math.ceil(employees.length / displayCount);

  // const handleSwipe = (deltaX) => {
  //   if (deltaX > 0) {
  //     setStartIndex((prevStartIndex) => Math.max(0, prevStartIndex - displayCount));
  //   } else {
  //     setStartIndex((prevStartIndex) => Math.min(employees.length - displayCount, prevStartIndex + displayCount));
  //   }
  // };

  // const swipeHandlers = useSwipeable({
  //   onSwipedLeft: () => handleSwipe(-1),
  //   onSwipedRight: () => handleSwipe(1),
  //   preventDefaultTouchmoveEvent: true,
  //   trackMouse: true,
  // });

  const handleScroll = (e) => {
    const scrollLeft = e.target.scrollLeft;
    const containerWidth = e.target.offsetWidth;
    const slideWidth = (containerWidth / displayCount);
    const newStartIndex = Math.round(scrollLeft / slideWidth) + 1;

    if (newStartIndex >= 0 && newStartIndex <= employees.length - displayCount) {
      setStartIndex(newStartIndex);
    }
  };

  return (
    <div className="employee-slideshow">
      <div
        // {...swipeHandlers}
        className="slides-container w-[80%] mx-auto overflow-hidden relative"
        ref={slidesRef}
        onScroll={handleScroll}
      >
        <div className="flex gap-4 transition-transform ease-in-out transform" style={{ transform: `translateX(-${startIndex * (100 / displayCount)}%)` }}>
          {employees.map((employee, index) => (
            <div key={index} className={`${additional} slide flex-shrink-0 lg:w-1/4 md:w-1/3 max-sm:w-2/5`}>
              {employee.link ? (
                <Link to={employee.link} target="_blank" rel="noopener noreferrer">
                  <img
                    src={employee.image}
                    alt={employee.name}
                    className="employee-image rounded-lg object-cover w-full lg:h-64 max-lg:h-30"
                    onMouseEnter={() => setHoveredEmployee(employee)}
                  />
                  <div className={`employee-info ${textalign} mt-4`}>
                    <div className="employee-name font-bold text-lg max-sm:text-sm">{employee.name}</div>
                    <div className="employee-role text-sm">{employee.role}</div>
                  </div>
                </Link>
              ) : (
                <>
                  <img
                    src={employee.image}
                    alt={employee.name}
                    className="employee-image rounded-lg object-cover w-full h-64"
                    onMouseEnter={() => setHoveredEmployee(employee)}
                  />
                  <div className={`employee-info ${textalign} mt-4`}>
                    <div className="employee-name font-bold text-lg">{employee.name}</div>
                    <div className="employee-role text-sm">{employee.role}</div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-center mt-4">
        {Array.from({ length: totalPages }).map((_, pageIndex) => (
          <button
            key={pageIndex}
            className={`page-dot w-10 h-6 mx-1 rounded-[30px] border border-black-500 ${pageIndex === Math.floor(startIndex / displayCount) ? 'bg-red-500 border-red-500' : 'bg-white'}`}
            onClick={() => handlePageClick(pageIndex)}
          ></button>
        ))}
      </div>
      {hoveredEmployee.testimonial ? (
        <div className="testimonial bg-gray-700 text-white max-sm:px-4 px-40 pt-8 mt-4 pb-[12%] mb-[-10%]">
          <h2 className="text-xl md:text-4xl font-bold" style={{ color: "#C30D3F" }}>{hoveredEmployee.name}</h2>
          <p className="text-sm md:text-base w-[80%]">{hoveredEmployee.testimonial}</p>
        </div>
      ) : null}
    </div>
  );
};

export default Slideshow;
