import React from 'react';
// import rectangle from "../assets/Rectangle.png";
import { Link } from 'react-router-dom';
import ghtvid from '../assets/ght_intro.MP4';
import bee from "../assets/bee4.png";
import ghtplain from "../assets/ght_plain.png";
import DDS from "../assets/DDS.png";
import BDS from "../assets/BDS.png";
import DPAS from "../assets/DPAS1.jpeg";
import HDDS from "../assets/HDDS.png";
import STAR from "../assets/STAR.png";
import '../stylesheets/honeycomb.css';
import Logo from './logo';

const HoneyComb = () => {
    return (
        <div className='sol'>
        <div className="text-left lg:mx-40 md:mx-40 mx-20 mt-12 items-center">
            <p className="font-bold lg:text-[1.2em]">Solutions</p>
            <h3 className="lg:text-[2.5em] font-bold text-[#C30D3F]">Featuring: The Stars</h3>
            <div className="flex flex-col lg:flex-row gap-5 justify-between items-center">
                <p className="font-bold lg:w-[35%]">Designing, Integrating, and providing you with effective end-to-end solutions.</p>
                <Link className='z-30' to="/solutions">
                    <button className="border rounded-full text-white bg-[#DC154B] py-2 px-4 self-end text-sm">Project Gallery</button>
                </Link>
            </div>
        </div>
        <div className='relative mx-auto rounded-md z-10 relative'>
            <div className='mb-40 -mt-10 lg:-ml-20'>
            <ul class="honeycomb">
                <li  class="honeycomb-cell">
                    <img  class="honeycomb-cell_image2" src={ghtplain}/>
                    <div class="honeycomb-cell_title"></div>
                </li>
                <li class="honeycomb-cell">
                    <Link to="/solutions#DDS">
                    <img class="honeycomb-cell_image" src={DDS}/>
                    <div class="honeycomb-cell_title">DDS</div>
                    </Link>
                </li>
                <li class="honeycomb-cell">
                    <Link to="/solutions#BDS">
                    <img class="honeycomb-cell_image" src={BDS}/>
                    <div class="honeycomb-cell_title">BDS</div>
                    </Link>
                </li>
                <li class="honeycomb-cell">
                    <Link to="/solutions#STAR">
                    <img class="honeycomb-cell_image" src={STAR}/>
                    <div class="honeycomb-cell_title">STAR</div>
                    </Link>
                </li>
                <li class="honeycomb-cell">
                    <Link to="/solutions#DPAS">
                    <img class="honeycomb-cell_image" src={DPAS}/>
                    <div class="honeycomb-cell_title">DPAS</div>
                    </Link>
                </li>
                <li class="honeycomb-cell">
                    <Link to="/solutions#ADSR">
                    <img class="honeycomb-cell_image" src={HDDS}/>
                    <div class="honeycomb-cell_title">ADSR</div>
                    </Link>
                </li>
                <li  class="honeycomb-cell">
                    <img  class="honeycomb-cell_image2" src={ghtplain}/>
                    <div class="honeycomb-cell_title"></div>
                </li>
            </ul>
            </div>
            <div className="relative z-10 relative m-4">   
                <img src={bee} alt="" className="w-[20%] absolute right-10 transform -translate-y-2/3 z-1 max-sm:hidden" />
                <h1 className="text-red-800 z-2 top-20 left-20 text-center lg:text-[2.5em] font-bold text-[#C30D3F]">Watch the Automations in Action!</h1>
                <video src={ghtvid} autoPlay loop muted className="lg:w-[65%] w-[100%] h-auto mx-auto mt-4 mb-20 object-center z-3 rounded" />
            </div> 
            </div>
            <Logo />
        </div> 
    )
}

export default HoneyComb;
